<script setup
        lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <mask id="mask0_741_11061" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
    <rect width="16" height="16" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_741_11061)">
    <path d="M3.33203 14V3.33333C3.33203 2.96667 3.46259 2.65278 3.7237 2.39167C3.98481 2.13056 4.2987 2 4.66536 2H8.66536V3.33333H4.66536V11.9667L7.9987 10.5333L11.332 11.9667V7.33333H12.6654V14L7.9987 12L3.33203 14ZM11.332 6V4.66667H9.9987V3.33333H11.332V2H12.6654V3.33333H13.9987V4.66667H12.6654V6H11.332Z" fill="currentColor"/>
  </g>
</svg>
</template>

<style scoped>

</style>